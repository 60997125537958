<template>
  <div>
    <el-drawer :visible.sync="params.show" :before-close="close" size="30%" :modal="false"  :with-header="false" @open="openDrawer">
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="120px">
        <el-form-item label="标题(中文)" prop="title_cn">
          <el-input v-model="ruleForm.title_cn"></el-input>
        </el-form-item>
        <el-form-item label="标题(英文)" prop="title_us">
          <el-input v-model="ruleForm.title_us"></el-input>
        </el-form-item>
        <el-form-item label="描述(中文)" prop="subhead_cn">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" v-model="ruleForm.subhead_cn"></el-input>
        </el-form-item>
        <el-form-item label="描述(英文)" prop="subhead_us">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" v-model="ruleForm.subhead_us"></el-input>
        </el-form-item>
        <el-form-item label="跳转路径" prop="route">
          <el-input v-model="ruleForm.route"></el-input>
        </el-form-item>
        <!-- <el-form-item label="跳转携带参数" prop="subhead_us">
          <el-input v-model="ruleForm.subhead_us"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { editTechnical } from '@/api/technical/technical'
export default {
  components: {
    
  },
  data() {
    return {
      ruleForm: {
        id: '',
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        route: '',
        param: '',
      }
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.$emit('close')
    },
    openDrawer () {
      this.ruleForm = this.params.data
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = await editTechnical(this.ruleForm)
        if (result.success) {
          this.$message.success('修改成功')
          this.close()
        }
      })
    },
  },
  computed: {
  },
}
</script>
<style scoped lang="less">

</style>
