<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>技术服务管理</el-breadcrumb-item>
    </el-breadcrumb>
    
    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="technicalList"
      v-bind="otherProps"
      :listCount="technicalTotal"
    >
      <template #operation="scope">
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
      </template>
    </table-ui>
    <edit-technical-drawer :params="editInfo" @close="editDrawerlose"></edit-technical-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/technicalConfig.js'
import editTechnicalDrawer from './cnps/edit-technical-drawer.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      editInfo: {
        show: false,
        data: null,
      }
    }
  },
  components: {
    TableUi,
    editTechnicalDrawer
  },
  mounted() {
    this.getTechnicalList()
  },
  computed: {
    ...mapState('technical', ['technicalList', 'technicalTotal'])
  },
  methods: {
    ...mapActions('technical', ['getTechnicalList']),
    editClick (row) {
      this.editInfo.data = {
        id: row.id,
        title_cn: row.title_cn,
        title_us: row.title_us,
        subhead_cn: row.subhead_cn || '',
        subhead_us: row.subhead_us || '',
        route: row.route,
        param: row.param || {},
      }
      this.editInfo.show = true
    },
    editDrawerlose () {
      this.editInfo.show = false
      this.getTechnicalList()
    }
  },
  
}
</script>

<style lang="less" scoped>

</style>